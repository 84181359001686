import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  public post = null;
  constructor(
    private activeRoute: ActivatedRoute,
    private postService: PostService
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => {
      const { postId } = params;
      this.getPosts(postId)
    });
  }

  getPosts(postId) {
    this.postService.publicGetRequest(`post/get/${postId}`).subscribe((res) => {
      const {
        data: post
      } = res;
      this.post = post;
      this.post.createdAt = moment(post.createdAt).format('DD/MM/YYYY')
      
    }, error => {
      console.log(error);
    });
  }
}
