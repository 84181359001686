<section class="posts">
    <ng-container *ngIf="posts.length > 0">
        <main class="cards" *ngFor="let post of posts; let i = index">
            <section class="card" [ngClass]="i%2 === 0 ? 'card-one': 'card-two'"
                style="background-image: linear-gradient(120deg, #755BB4 0%, #130730 80%)">
                <div class="top">
                    <div class="date">{{post.createdAt}}</div>
                    <div class="actions">
                        <i *ngFor="let tag of post.tags">#{{ tag }} </i>
                    </div>
                </div>
                <div class="bottom">
                    <div class="author">
                        <div><img class="author-img"
                                src="https://founder.nex2ech.lk/assets/Images/123016282_1230620220645010_1510726153310935145_n-removebg-preview.png"
                                alt="Dew"></div>
                        <span>{{post.author}}</span>
                    </div>
                    <h1 (click)="readMore(post)">{{post.title}}</h1>
                    <div class="text">
                        <p>{{post.summery}}á</p>
                        <button (click)="readMore(post)">Read More</button>
                    </div>
                </div>
            </section>
        </main>
        <app-pagination [(paginate)]="paginate" (paginateChange)="pageChange($event)"></app-pagination>
    </ng-container>
</section>