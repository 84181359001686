<!-- <nav class="pagination-center">
  <ul class="pagination justify-content-center pagination-lg flex-wrap">
    <li class="page-item" [ngClass]="{'disabled': (paginate.currentPage <=  1)}"> <a class="page-link"
        (click)="doPrevPaginate(paginate.currentPage)">Prev</a> </li>
    <li *ngFor="let page of pageArray" class="page-item" [ngClass]="{'active': ( page + 1) === paginate.currentPage}">
      <a class="page-link" (click)="doPaginate(page + 1)" *ngIf=" ( page + 1) !== paginate.currentPage">
        {{page + 1}}
      </a>
      <span class="page-link active" *ngIf=" (page + 1) === paginate.currentPage">
        {{page + 1}}
      </span>
    </li>
    <li class="page-item" [ngClass]="{'disabled':(paginate.pages <= paginate.currentPage)}"> <a class="page-link"
        (click)="doNextPaginate(paginate.currentPage)">Next</a> </li>
  </ul>
</nav> -->

<div class="center">
  <div class="pagination">
    <a (click)="doPrevPaginate(paginate.currentPage)">&laquo;</a>
    <ng-container *ngFor="let page of pageArray">
      <a (click)="doPaginate(page + 1)" [ngClass]="{'active': ( page + 1) === paginate.currentPage}">{{page + 1}}</a>
    </ng-container>
    <a  (click)="doNextPaginate(paginate.currentPage)">&raquo;</a>
  </div>
</div>