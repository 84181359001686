import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {

  public posts = [];
  public paginate = {
    pages: 2,
    currentPage: 1
  };

  public filters = {
    page: 1,
    searchKey: '',
    sort: null,
  };

  constructor(
    private router: Router,
    private postService: PostService
  ) { }

  ngOnInit(): void {
    this.getPosts(this.filters)
  }

  pageChange(event) {
    this.filters.page = event.currentPage;
    this.paginate.currentPage = event.currentPage;
    this.getPosts(this.filters)
  }


  getPosts(filters) {
    console.log('getPosts');
    
    let url = `post/get-all?page=${filters.page}&sort=${filters.sort}&filterKey=${filters.filterKey}`
    if (filters.searchKey !== null && filters.searchKey !== '') {
      url = url.concat(`&searchKey=${filters.searchKey}`)
    }
    this.postService.publicGetRequest(url).subscribe((res) => {
      const {
        data: {
          pageCount,
          posts
        }
      } = res;
      this.posts = posts.map(post => {
        post.createdAt = moment(post.createdAt).format('DD/MM/YYYY')
        return post;
      });
      this.paginate.pages = pageCount;
      
    }, error => {
      console.log(error);
    });
  }

  readMore(post) {
    const url = `view/${post._id}`;
    this.router.navigateByUrl(url);
  }

}
