import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

interface Paginate {
	pages: Number;
	currentPage: Number;
}

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class PaginationComponent implements OnInit {

	@Input() paginate: Paginate;
	@Output() paginateChange: EventEmitter<Paginate> = new EventEmitter<Paginate>();
	public pageArray: Array<Number> = new Array<Number>();


	constructor() { }

	ngOnInit() {
		this.pageArray = Array(this.paginate.pages).fill(null).map((_x: any, i: number) => i);

	}
	doPaginate(page) {
		if (page === 0 || page > this.paginate.pages) {
			return;
		}
		this.paginate.currentPage = page;

		this.paginateChange.emit(this.paginate);
	}

	doPrevPaginate(page) {
		this.doPaginate(page - 1);
	}

	doNextPaginate(page) {
		this.doPaginate(page + 1);
	}

}
