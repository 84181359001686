import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PostService {
  private apiEndPoint = environment.API_END_POINT;

  constructor(protected http: HttpClient) { }


  public publicGetRequest(endPoint: string, params?: any): Observable<any> {
    const url = `${this.apiEndPoint}/${endPoint}`;
    return this.http.get<any>(url, { params }).pipe(map((res: any) => res));
  }

  public postRequest(endPoint: string, data: any): Observable<any> {
    const url = `${this.apiEndPoint}/${endPoint}`;
    return this.http.post<any>(url, JSON.stringify(data));
  }

  public putRequest(endPoint: string, data: any): Observable<any> {
    const url = `${this.apiEndPoint}/${endPoint}`;
    return this.http.put<any>(url, JSON.stringify(data));
  }
}
