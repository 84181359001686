<ng-container *ngIf="post !== null">
    <div class="post container">
        <div class="post-meta">
            <div>{{post.createdAt}}</div>
            <div><img class="author-img"
                    src="https://founder.nex2ech.lk/assets/Images/123016282_1230620220645010_1510726153310935145_n-removebg-preview.png"
                    alt="Dew"></div>
            <div>{{post.author}}</div>
            <div>
                <i *ngFor="let tag of post.tags">#{{ tag }} </i>
            </div>
        </div>
        <div class="post-header">{{post.title}}</div>
        <br>
        <div class="post-content">
            <p [innerHtml]="post.content">
            <p>
        </div>
    </div>
</ng-container>