
<section class="main-wrapper">
  <router-outlet></router-outlet>
</section>

<!-- <div class="coming wrapper">
    <h1>coming soon<span class="dot">.</span></h1>
    <p>
        <img src="../assets/images/logo-white.png" alt="https://www.nex2ech.lk">
    </p>
    <div class="icons">
        <a href=""><i class="fa fa-twitter"></i></a>
        <a href=""><i class="fa fa-youtube-play"></i></a>
        <a href=""><i class="fa fa-paper-plane"></i></a>
    </div>
</div> -->

<footer class="footer-distributed">

  <div class="footer-left">

    <IMG SRC="https://www.nex2ech.lk/assets/png/logo-white.png" ALT="" HEIGHT=150>

    <p class="footer-links">
      <a href="https://www.nex2ech.lk/">Home</a> ·
      <a href="https://www.nex2ech.lk">Service</a> ·
      <a href="https://blog.nex2ech.lk/">Blog</a> ·
      <a href="https://founder.nex2ech.lk/">About</a> ·
      <a href="https://www.nex2ech.lk">Contact</a>
    </p>

    <p class="footer-company-name">www.nex2ech.lk &copy; 2022</p>
  </div>

  <div class="footer-center">

    <!-- <div>
        <i class="fa fa-map-marker"></i>
        <p><span>21 Revolution Street</span> Rutland, VT</p>
      </div> -->

    <!-- <div>
        <i class="fa fa-phone"></i>
        <p>911</p>
      </div> -->

    <!-- <div>
        <i class="fa fa-envelope"></i>
        <p><a href="mailto:support@company.com">support@company.com</a></p>
      </div> -->

  </div>

  <div class="footer-right">
    <!--   
      <p class="footer-company-about">
        <span>About this guy</span> Blah Blah..Blah blah bl;ah blah
      </p> -->

    <!-- <div class="footer-icons">

        <a href="#"><i class="fa fa-facebook"></i></a>
        <a href="#"><i class="fa fa-twitter"></i></a>
        <a href="#"><i class="fa fa-linkedin"></i></a>
        <a href="#"><i class="fa fa-github"></i></a>

      </div> -->

  </div>

</footer>